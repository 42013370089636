@import "../../../Assets/Scss/veriables.scss";
@import "~react-pro-sidebar/dist/scss/styles.scss";

.border_bottom_header
{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid rgb(194, 191, 191);
    background-color: #f4f6f7;
}

// .img_width
// {
//     width: 80%;
// }

.font_header_admin
{
    font-size: 80%;
    font-weight: $font-weight-800;
}


.font_header_admin1
{
    font-size: 80%;
    font-weight: $font-weight-800;
    color: $blue !important;
}

.button_profile
{
    background-color: #EDECFF;
    color: #3E50F7;
    border-color: #EDECFF;
}

.dropdown_style
{
    box-shadow: 1px 1px 7px 7px rgba(211, 211, 211, 0.263);
    border-radius: 10px;
    border: 1px dashed $blue;
}

.btn_style_dash
{
    border: 1px dashed $blue !important;
}

.btn_style_dash:hover
{
    border: 1px dashed $blue !important;
    color: $blue !important;
}

.btn_style_dash:focus
{
    border: 1px dashed $blue !important;
    color: $blue !important;
}



@media screen and (max-width: 980px) 
{

    .img_width
{
    width: 30%;
}


  }