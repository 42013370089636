@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-family: "Poppins" !default;
$font-family-Dosis: "Dosis" !default;

$white: #fff !default;
$light-gray: rgba(255, 255, 255, 0.5) !default;

$red: #d41313 !default;

$primary: #0575E6 !default;

$black: #131313 !default;
$light-black: #201f23 !default;
$dark-black: #0e0e0e !default;
$blue: #0575E6 !default;
$gray: Gray !default;
$lightgray: lightgray !default;
$green: #42D742 !default;
$darkblue: rgb(20,38,100);


$border: 1px solid #D1D1D1 !default;
$bold-border: 3px solid #b59b41 !default;

$border-radius-10: 10px !default;
$border-radius-20: 20px !default;
$border-radius-24: 24px !default;

$text-uppercase: uppercase !default;


$font-8: 8px !default;
$font-9: 9px !default;
$font-10: 10px !default;
$font-11: 11px !default;
$font-12: 12px !default;
$font-14: 14px !default;
$font-15: 15px !default;
$font-16: 16px !default;
$font-17: 17px !default;
$font-18: 18px !default;
$font-20: 20px !default;
$font-22: 22px !default;
$font-24: 24px !default;
$font-26: 26px !default;
$font-28: 28px !default;
$font-32: 32px !default;
$font-36: 36px !default;
$font-50: 50px !default;
$font-40: 40px !default;
$font-144: 144px !default;

$font-weight-300: 300 !default;
$font-weight-400: 400 !default;
$font-weight-500: 500 !default;
$font-weight-600: 600 !default;
$font-weight-700: 700 !default;
$font-weight-800: 800 !default;
$font-weight-900: 900 !default;
$font-weight-1200: 1900 !default;
